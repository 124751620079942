/* Theme Name: Vestonic - Landing page Template
   Author: Themesdesign
   Version: 1.0.0
   Created: Dec 2016
   File Description:Main CSS File
*/


/*---------------------

Table of contents

1. Loader
2. Helper classes 
3. Buttons
4. Navbar
5. Home
6. Services
7. Features
8. Testimonials / Clients
9. Team
10. CTA Section
11. Pricing
12. Fun facts
13. Contact
14. Footer
15. Animation - Magnific popoup
16. Responsive

---------------------*/

.deskripsi {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

.deskripsi-testi {
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  font-style: italic;
  color: white
}

.content-center {
  display: flex;
  justify-content: center
}

.content-center a {
  display: flex;
  justify-content: center
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.font-helloween {
  font-family: "festival";
  color: #fff;
  font-weight: bold;
  font-size: 50px;
  text-align: center;
}

.font-helloween-h2 {
  font-family: "festival";
  color: #fff;
  font-weight: bold;
  font-size: 35px;
  text-align: center;
}

.montserrat-bold {
  font-family: 'monstserrat-bold';
  color: #0088d6;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-top: -10px;
  margin-bottom: 0px;
}

.montserrat-bold-polos {
  font-family: 'monstserrat-bold';
  color: #fff;
}

.montserrat {
  font-family: 'monstserrat-semi-bold';
  font-weight: bolder;
}

.text-white {
  color: 'white';
}


body {
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-size: 14px;
  overflow-x: hidden;
  position: relative;
}

a {
  text-decoration: none !important;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 650;
}


h1{
  font-size: 45px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
  margin-bottom: 0.5px;
}

.heading-1 p {
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 10px;
}

h4 {
  font-size: 18px;
  margin-bottom: 0.5px;
}

h5 {
  font-size: 14px;
  margin-bottom: 0.5px;
}

h6 {
  font-size: 12px;
}

.bg-header-banner {
  background-image: url('../img/img1.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-header-city {
  background-color: #fff;
}

.bg-header-about {
  background-color: #194f14;
}



/* === 1.Loader === */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 9999999;
}

#status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.spinner {
  margin: 0 auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid rgba(56, 83, 233, 0.2);
  border-right: 5px solid rgba(56, 83, 233, 0.2);
  border-bottom: 5px solid rgba(56, 83, 233, 0.2);
  border-left: 5px solid #754c29;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}





/* ==== 2. Helper classes ==== */

.section {
  padding-top: 40px;
  padding-bottom: 50px;
}

.title-box-speaker {
  background-color: #f7941d;
  padding: 5px;
  color: #fff;
  border-radius: 25px;
}

.title-box-category-header {
  background-color: #f7941d;
  padding: 5px;
  color: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-weight: bold;
  height: 200px;
}

.title-box-category-bottom {
  background-color: #92278f;
  padding: 5px;
  color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  height: 175px;
}

.title-box-ticket-header {
  background-color: #f7941d;
  padding: 5px;
  color: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  font-weight: bold;
  height: 150px;
}

.title-box-ticket-bottom {
  background-color: #92278f;
  padding: 5px;
  color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  height: 200px;
}

.title-box-ticket-qr {
  background-color: #92278f;
  padding: 5px;
  color: #fff;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  height: 350px;
}

.title-box {
  padding: 10px;
  margin-bottom: 40px;
  /* margin-top: 40px; */
  color: #fff;
}

.title-box-faq {
  padding-bottom: 20px;
  color: #fff;
}

.title-box-faq h4 {
  width: 75%;
  line-height: 24px;
  margin: 10px auto;
  font-size: 16px;
  color: #fff;
}

.title-box-faq p {
  width: 75%;
  line-height: 24px;
  margin: 10px auto;
  font-size: 16px;
  color: #fff;
}

.title-box i{
  font-size: 36px;
  color: #fff;
}

.title-box p {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  margin-top: 10px;
  color: #fff;
}

.title-box h2 {
  font-weight: 700;
  margin-top: 10px;
  color: #fff;
}

.title-box h3 {
  font-size: 22px;
  color: #fff;
}

.title-box h4 {
  width: 60%;
  line-height: 24px;
  margin: 10px auto;
  font-size: 16px;
  color: #fff;
}

.bg-lightgray {
  background-color: #fafafa;
}

.bg-lightblue {
  background-color: #eee1be;
}

.bg-dark2 {
  background-color: #000;
}

.text-custom {
  color: #f56363;
}

.text-warning {
  color: #ffbd4a;
}

.margin-t-20 {
  margin-top: 20px;
}

.margin-t-30 {
  margin-top: 30px;
}

.margin-t-50 {
  margin-top: 50px;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-5 {
  margin-left: 1.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-5 {
  margin-right: 1.5rem !important;
}

.mb-3 {
  margin-right: 1rem !important;
}

.mb-5 {
  margin-right: 1.5rem !important;
}

.font-2 {
  font-style: italic;
  font-weight: 400;
  font-family: 'Crete Round', serif;
}

.vertical-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
  background: #f7941d;
  border-radius: 40px;
  margin-bottom: 50px;
}

.vertical-content-faq {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
  /* background: #f7941d; */
  background: #000;
  border-radius: 40px;
  margin-bottom: 10px;
}

.vertical-content-jadwal {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
  background: #221330;
  border-radius: 40px;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 50px;
  margin-right: 50px;
}

.vertical-content-jadwal-margin {
  padding-left: 100px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.vertical-content-faq-margin {
  padding-left: 100px;
  padding-right: 100px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.vertical-content-box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
  background: #f7941d;
}


/* Background images css */
.bg-home {
  /* background: url("../images/chapter2/LandingPage_BG.jpg"); */
  background-size: cover;
  background-color: #004c88;
  background-position-y: -200px;
  background-repeat: no-repeat;
  margin-bottom: 200px;
}

.bg-singaraja {
  background-size: cover;
  background-color: #8D0000;
  background-position-y: -200px;
  background-repeat: no-repeat;
}

/* .bg-home-ticket {
  background: url("../img/bg-ticket.png");
  background-size: contain;
  background-color: #352741;
  background-position-y: -200px;
  background-repeat: no-repeat;
  margin-bottom: 200px;
} */

/* .bg-pattern {
  background: url("../img/bg-pattern.png");
} */

/* .bg-eticket {
  background: url("../images/etiket-bg.png");
  background-size: cover;
  background-color: #221330;
  background-position-y: -200px;
  background-repeat: no-repeat;
  margin-bottom: 200px;
} */


/* Social */
.social li a {
  display: block;
  height: 36px;
  width: 36px;
  color: white !important;
  line-height: 36px;
  text-align: center;
  text-decoration: none !important;
  border-radius: 50%;
  border: 1px solid white;
}

.social li a:hover {
  color: #ffffff !important;
  border-color: transparent;
}

/* ==== 3. Buttons ==== */

.btn {
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  letter-spacing: 0.03em;
  padding: 10px 30px;
  border-radius: 20px;
  -webkit-transition: all 0.4s ease-out;
     -moz-transition: all 0.4s ease-out;
       -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
}

.btn-act {
  display: flex;
  color: #004c88 !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.03em;
  padding: 10px 30px;
  border-radius: 20px;
  outline-color: #004c88;
  border: 3px solid #004c88;
  text-align: center;
}

.btn:hover {
  box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.3);
}

.btn-sm {
  padding: 7px 24px;
  font-size: 12px;
}

.btn-custom-register {
  background-color: #ff000e;
}

.btn-custom {
  background-color: #ff000e;
}

.btn-custom:hover {
  border: 3px solid white;
}

.btn-link {
  color: #f7941d !important;
  box-shadow: none !important;
  padding: 0;
}

.bg-facebook:hover {
  background-color: #3b5998 !important;
}

.bg-twitter:hover {
  background-color: #00aced !important;
}

.bg-linkedin:hover {
  background-color: #007bb6 !important;
}

.bg-dribbble:hover {
  background-color: #ea4c89 !important;
}

.bg-googleplus:hover {
  background-color: #dd4b39 !important;
}

.bg-color-edufest {
  /* background: url("../images/chapter2/LandingPage_BG.jpg"); */
  background-size: cover;
  background-color: #004c88;
}

.bg-color-faq {
  background-color: #f7941d;
}




/*===== 4. Navbar Custom ======*/

.navbar-custom{
  padding: 15px 0px;
  width: 100%;
  border-radius: 0px;
  z-index: 999;
  margin-bottom: 0px;
  -webkit-transition: all 0.5s ease-in-out;
     -moz-transition: all 0.5s ease-in-out;
       -o-transition: all 0.5s ease-in-out;
          transition: all 0.5s ease-in-out;
}

.sticky-wrapper {
  position: absolute;
  width: 100%;
}

.logo {
  font-weight: 500;
  font-size: 26px;
  color: #585858 !important;
}

.logo span {
  color: #f56363;
}

.navbar-custom .navbar-nav li a {
  color: #fff;
  font-size: 13px;
  background-color: transparent !important;
  padding: 7px 10px;
  margin: 0 5px;
  font-weight: 600;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  border-radius: 3px;
}

.navbar-custom .navbar-nav li a:hover,.navbar-custom .navbar-nav li a:focus,.navbar-custom .navbar-nav li a:active {
  color: #0088d6;
}

.navbar-custom .navbar-nav li a.active{
  color: #fff !important;
}

.is-sticky .navbar-custom{
  margin-top: 0px;
  padding: 10px 0px;
  background-color: #001345;
    -moz-box-shadow: 0 10px 33px rgba(0,0,0,.1);
  -webkit-box-shadow: 0 10px 33px rgba(0,0,0,.1);
  box-shadow: 0 10px 33px rgba(0,0,0,.1);
}

.navbar-toggle {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.navbar-custom .btn-custom {
  margin-top: 8px;
  margin-left: 20px;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
  background-color: transparent;
  border-color: #337ab7;
}


/* Navbar white */

.navbar-header-white {
  background-color: #ffffff !important;
  padding: 15px 0px !important;
}

.navbar-header-white .logo {
  color: #222328 !important;
}


.navbar-header-white.navbar-custom .navbar-toggle {
  color: #ffffff;
}

/*===== 5.Home ======*/

.home-fullscreen {
  height: 100%;
  min-height: 400px;
}

.home-wrapper-alt{
  display: table-cell;
  vertical-align: middle;
}

.home-wrapper h1{
  line-height: 54px;
  margin-top: 0;
  font-weight: bold;
}

.home-wrapper h4 {
  line-height: 26px;
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 15px;
  font-weight: normal;
}

.video-btn {
  color: #585858 !important;
  letter-spacing: 1px;
  outline: none !important;
  box-shadow: none !important;
}

.video-btn i {
  margin-right: 7px;
  height: 24px;
  width: 24px;
  background-color: rgba(255, 255, 255, 0.5);
  color: #222328;
  line-height: 24px;
  border-radius: 50%;
  padding-left: 3px;
  font-size: 12px;
}

.full-screen {
  display: table;
  height: 100%;
  width: 100%;
}


/* === HOME VARIATIONS === */
.home-small {
  padding: 210px 0 160px 0;
}


/* Home intro */
.home-intro h2 {
  color: #fff;
  font-size: 67px;
  line-height: 61px;
  margin: 0 0 40px;
  font-weight: 600;
}

.home-intro p {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  padding-right: 25%;
}

.home-intro .intro-form {
  background: #fff;
  padding: 0 40px 40px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 2px 8px 15px #dfdede;
}

.home-intro .intro-form input {
  height: 46px;
  margin-bottom: 18px;
  box-shadow: none;
  border: 2px solid #e5e5e5;
  width: 100%;
  padding: 15px 20px;
  color: #000;
  outline: none !important;
}

.home-intro .intro-form input:focus {
  border: 2px solid #999;
}

.home-intro .intro-form h5 {
  color: #fff;
  font-size: 26px;
  font-family: 'Crete Round', serif;
  background: #f56363;
  padding: 25px 40px 25px;
  margin: 0 -40px 50px;
  font-weight: 500;
}

.home-intro .intro-form h5 span {
  display: block;
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0;
  font-family: Lato;
  opacity: 0.5;
  margin-top: 6px;
}

.home-intro .intro-form p {
  color: #999;
  padding: 17px 30px 0px;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  margin: 0px;
}


/* Home Showcase */
.home-showcase {
  padding: 210px 0px 0px 0px;
}

/* Home Header-2 */
.home-header-2 {
  padding: 240px 0px 160px 0px;
}


/* Home Slider */
.home-slider {
  padding: 250px 0 200px 0;
}
#home-slider {
  position: relative;
  top: 0px;
}

#home-slider .is-prev,#home-slider .is-next  {
  position: absolute;
  height: 80px;
  width: 80px;
  top: 50%;
  left: 20px;
  z-index: 999;
  margin-top: -50px;
  color: #222;
  line-height: 93px;
  text-align: center;
  font-size: 36px;
  cursor: pointer;
  opacity: 0.4;
  transition: .4s;
}

#home-slider .is-next {
  right: 20px;
  left: auto;
}

#home-slider .is-prev:hover,
#home-slider .is-next:hover {
  opacity: 1;
  transition: .4s;
}

#home-slider .flex-direction-nav {
  display: none;
}




/*===== 6.Services ======*/

.services-box {
  padding: 20px 0;
}

.services-box:hover i {
  color: #f56363;
}

.services-box i {
  font-size: 36px;
  float: left;
  line-height: 60px;
  width: 70px;
  -webkit-transition: all 0.4s ease-out;
     -moz-transition: all 0.4s ease-out;
       -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
}


.services-box p {
  margin-top: 15px;
  line-height: 26px;
  overflow: hidden;
}


/*===== 7.Features ======*/
.features {
  padding: 50px 0;
}

.features-box .number{
  height: 60px;
  width: 60px;
  background-color: #f56363;
  color: #ffffff;
  border: 2px solid #f56363;
  font-size: 24px;
  text-align: center;
  line-height: 56px;
  border-radius: 50%;
  font-weight: bolder;
}

.features-box h3 {
  line-height: 30px;
}

.features-box h4 {
  line-height: 24px;
  color: #666;
  margin-bottom: 20px;
}

.features-box p {
  line-height: 28px;
  margin-top: 20px;
  font-size: 16px;
  color: #848e92;
}

.img-shadow {
  box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 8px 42px 0px rgba(0, 0, 0, 0.1);
}

.img-fluid-basic {
  height: auto;
  max-width: 100%;
}

.img-fluid-card-kampus {
  height: auto;
  max-width: 75%;
  vertical-align: middle;
}

.img-fluid-city {
  margin-left: -50px;
  margin-top: 40px;
  height: 425px;
}

.img-center {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.img-fluid-logo-banner {
  height: auto;
  max-width:25%;
}

.img-fluid-small {
  height: auto;
  max-width: 70%;
}

.img-fluid-speaker {
  height: auto;
  width: 100%;
}

.img-fluid-small-2 {
  height: auto;
  width: 70%;
}

.img-fluid-banner {
  height: auto;
  width: 50%;
  margin-top: 250px;
}

.img-fluid-banner-right {
  height: auto;
  width: 100%;
  margin-top: 250px;
}

/* .img-left {
  background-repeat: no-repeat;
  background-image: url('../images/chapter3/astronot2.png');
  background-blend-mode: lighten;
} */

/* === 8.Testimonials / Clients === */
.testimonial-box {
  background-size: cover;
  background-color: #011b42;
  background-repeat: no-repeat;
  border-radius: 30px;
  border: 1px solid #00e672;
  margin: 10px;
}

.testimonial-box h4 {
  line-height: 30px;
  font-family: 'Crete Round', serif;
  font-style: italic;
  font-weight: normal;
}

.testimonial-box .testi-user {
  width: 48px !important;
  height: 48px !important;
  margin: 10px auto;
}

.img-fluid-testimoni {
  background-color: #011b42;
  height: 200px;
  max-width: 200px;
  border-radius: 100px;
  border: 5px solid #00e672;
}

.client-images {
  padding-top: 70px;
}

.client-images-university {
  padding-top: 150px;
}

.client-images img {
  max-height: 50px;
  width: auto !important;
  margin: 10px auto;
}

.owl-controls.clickable {
  display: none !important;
}



/* === 9.Team === */

.team-box {
  text-align: center;
  padding: 30px 20px;
  margin-top: 30px;
}

.team-box img {
  height: 120px;
  margin-bottom: 20px;
}

.team-box p {
  margin: 20px 0;
  line-height: 24px;
}
.team-box h6 {
  font-family: 'Crete Round', serif;
  font-weight: normal;
  font-size: 14px;
}

/* ====== 10.cta-section ======= */
.cta-section h4 {
  line-height: 30px;
  font-family: 'Crete Round', serif;
  font-style: italic;
  font-weight: normal;
}


/* === 11.Pricing === */

.pricing-box {
  text-align: center;
  box-shadow: 0.5rem 0.5rem #754c29, -0.5rem -0.5rem #cecece;
  padding: 20px;
  height: 250px;
  margin: 25px;
  border: 2px solid #000;
  border-radius: 50px;
  background-color: white;
}

.pricing-box.active {
    background-color: rgba(245, 99, 99, 0.07);
    border-color: #f56363;
}

.pricing-box.active .plan-title {
  color: #f56363;
}

.pricing-header .plan-title{
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}

.pricing-header .plan-price{
  font-size: 42px;
  font-family: 'Crete Round', serif;
}

.plan-features {
  margin-top: 40px;
}

.plan-features li {
  margin: 10px 0;
  line-height: 26px;
}

.plan-features li i {
  color: #0da357;
  margin-right: 5px;
}


/* === 12.Funfacts === */

.funfacts {
  color: #585858;
}

.funfacts .facts i {
  font-size: 36px;
}



/* === 13. Contact === */
.form-control {
  box-shadow: none !important;
  border: 2px solid #dddddd;
  height: 44px;
}

.contact-form .form-control {
  margin-bottom: 25px;
}

.parsley-errors-list {
  padding-left: 0;
}
.parsley-errors-list li {
  display: none;
}
.parsley-error {
  border-color: #f00 !important;
}

.error_message {
  padding: 0;
  margin: 0 0 10px;
  color: #f00;
  font-weight: 500;
}

.contact-loader {
  float: left;
}

#success_page {
  text-align: center;
  margin-top: 50px;
}

#success_page h3 {
  color: #0dba26;
}

#success_page p {
  font-family: 'Crete Round', serif;
}

.contact-footer {
  padding-bottom: 70px;
}

.contact-footer-box {
  padding-top: 30px;
}

.contact-footer-box i {
  font-size: 32px;
  margin-bottom: 20px;
  display: block;
}

.contact-footer-box h5 {
  color: #989898;
  font-weight: normal;
}


/* === 14.Footer === */

.footer {
  padding: 70px 0;
}

.footer h5{
  margin-bottom: 15px;
  font-size: 16px;
}

.footer ul {
  margin-bottom: 0;
}

.footer p {
  line-height: 24px;
}

.footer ul.footer-list li a {
  color: #fff;
  display: inline-block;
  padding: 5px 0;
  -webkit-transition: all 0.4s ease-out;
     -moz-transition: all 0.4s ease-out;
       -o-transition: all 0.4s ease-out;
      -ms-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
}

.footer ul.footer-list li a:hover  {
  color: #333;
}

.footer .social {
  margin-top: 20px;
  margin-bottom: 0;
}

.footer-alt .copy-rights{
  margin: 0;
  line-height: 60px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}


/* === 15.Animation === */
/**
 * Fade-zoom animation for Magnific-popup
 */

/* start state */
.my-mfp-zoom-in .zoom-anim-dialog {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out; 
     -moz-transition: all 0.2s ease-in-out; 
       -o-transition: all 0.2s ease-in-out; 
          transition: all 0.2s ease-in-out; 
  -webkit-transform: scale(0.8); 
     -moz-transform: scale(0.8); 
      -ms-transform: scale(0.8); 
       -o-transform: scale(0.8); 
          transform: scale(0.8); 
}

/* animate in */
.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
  opacity: 1;
  -webkit-transform: scale(1); 
     -moz-transform: scale(1); 
      -ms-transform: scale(1); 
       -o-transform: scale(1); 
          transform: scale(1); 
}

/* animate out */
.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
  -webkit-transform: scale(0.8); 
     -moz-transform: scale(0.8); 
      -ms-transform: scale(0.8); 
       -o-transform: scale(0.8); 
          transform: scale(0.8); 
  opacity: 0;
}

/* Dark overlay, start state */
.my-mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-out; 
     -moz-transition: opacity 0.3s ease-out; 
       -o-transition: opacity 0.3s ease-out; 
          transition: opacity 0.3s ease-out;
}

/* animate in */
.my-mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8;
}

/* animate out */
.my-mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}


/*======= 16.Responsive ======*/

@media (min-width: 200px) and (max-width: 768px) {

  .navbar-custom {
    background-color: #eee1be !important;
    padding: 10px 0px;
  }

  .navbar-custom .container{
    width: 90%;
  }

  .vertical-content-jadwal-margin {
    /* margin-left: -70px; */
    padding-left: 0px;
  }

  .bg-home {
    margin-bottom: 0px;
  }

  .padding-top-judul {
    padding-top: 350px
  }

  .img-fluid-logo-banner {
    height: auto;
    max-width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
  }

  .img-fluid-banner {
    height: auto;
    width: 50%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .img-fluid-city {
    margin-left: -4px;
    height: 255px;
  }

  .img-fluid-banner-right {
    margin-top: 0px;
  }

}

@media (max-width: 844px) {
  .vertical-content-jadwal-margin {
    padding-left: 15px;
  }

  .img-fluid-speaker {
    height: auto;
    width: 100%;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .montserrat-semibold {
    font-family: 'monstserrat-semi-bold';
    color: #fff;
    font-size: 42px;
    margin-top: -10px;
    margin-bottom: 0px;
    text-align: center;
  }

  .img-fluid-banner-right {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {

  .vertical-content {
    display: inherit !important;
    margin: auto;
  }

  .navbar-custom{
    margin-top: 0px;
    padding: 5px 0px !important;
    background-color: #ffffff;
    -moz-box-shadow: 0 10px 33px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 10px 33px rgba(0,0,0,.1);
    box-shadow: 0 10px 33px rgba(0,0,0,.1);
  }

  .tab-bg {
    background-color: #f7941d;
  }

  .logo {
    margin-top: 5px;
  }

  .features-box {
    text-align: center;
  }

  .features-box .number {
    margin: 0 auto;
  }

  .footer h5 {
    margin-top: 30px;
  }

  .title-box h4 {
    width: 95%;
    color: #fff;
  }

  .home-intro .intro-form {
    margin-top: 50px;
    margin-bottom: 100px;
  }

  .bg-home {
    margin-bottom: 0px;
  }

  .img-fluid-banner-right {
    margin-top: 0px;
  }
  
}

@media (max-width: 420px) {

  .home-wrapper h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .facts {
    margin-bottom: 30px;
  }

  .img-speaker {
    height: auto;
    max-width: 100%;
  }

  .bg-home {
    margin-bottom: 0px;
  }

  .img-fluid-banner-right {
    margin-top: 0px;
  }

}

.containerAccordion{
  width: 100%;
  max-width: 650px;
  margin: auto;
}

.accordion{
  width: 100%; 
  max-width: 700px;
  margin: 18px auto;
}

.accordionHeading .containerAccordion{
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  justify-content: space-between;
}

.accordionHeading{
  border: 2px solid #754c29;;
  cursor: pointer;
  color: black;
  padding: 14px 0;
}

.accordionHeading p{
  margin: 0;
  letter-spacing: 1.2px;
  font-weight: 600;
}

.accordionContent.show{
  height: 180px;
  opacity: 1;
}

.accordionContent{
  background-color: white;
  border: 2px solid black;
  height: 0;
  opacity: 0;
  display: flex;
  overflow: hidden;
  transition: all 600ms ease-in-out;
}

.event-label{
  color: #754c29;
}

.nav-item-register {
  color: #ffffff;
  font-family: "Montserrat", Helvetica;
  font-size: 20px;
  font-weight: 400;
}

.bg-oval-pink {
  margin: 10px;
  background-color: #ff013e;
  border-radius: 50px;
}

/* .bg-logo {
  background-image: url('./../images/logo-ss-1.svg');
  background-repeat: no-repeat;
} */

.heading {
  color: #194f14;
  font-family: "Montserrat", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 275px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
}

.card {
  height: 130px;
  left: 79px;
  position: relative;
  top: 328px;
  width: 319px;
}

.overlap-group {
  background-color: #194f14;
  border-radius: 0px 0px 24px 24px;
  height: 130px;
  position: relative;
  width: 315px;
}

.lokasi-medan {
  height: 457px;
  left: 0;
  position: absolute;
  top: 115px;
  width: 315px;
}

.text-wrapper-3 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 19.9px;
  position: absolute;
  text-align: center;
  top: 68px;
  width: 315px;
}

.text-wrapper-4 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 1px;
  letter-spacing: 0;
  line-height: 19.9px;
  position: absolute;
  text-align: center;
  top: 23px;
  width: 314px;
}

.text-wrapper-5 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 19.9px;
  position: absolute;
  text-align: center;
  top: 48px;
  width: 315px;
}

.text-wrapper-6 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 19.9px;
  position: absolute;
  text-align: center;
  top: 44px;
  width: 315px;
}

.text-wrapper-7 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 1px;
  letter-spacing: 0;
  line-height: 19.9px;
  position: absolute;
  text-align: center;
  top: 19px;
  width: 314px;
}

.overlap-group {
  background-color: #001345;
  border-radius: 0px 0px 24px 24px;
  height: 130px;
  position: relative;
  width: 315px;
}

.overlap-2 {
  /* background-image: url('../images2/Display_WEF_DPS.jpg'); */
  background-color: red;
  background-size: 100% 100%;
  /* background-size: 200px 100%; */
  height: 458px;
  left: -79px;
  position: relative;
  top: -1px;
  width: 474px;
}

/* .overlap-3 {
  background-image: url('../images/mask-group-4.png');
  background-size: 100% 100%;
  height: 458px;
  left: -79px;
  position: relative;
  top: -1px;
  width: 474px;
} */

/* .overlap-4 {
  background-image: url('../images/mask-group-2.png');
  background-size: 100% 100%;
  height: 458px;
  left: -79px;
  position: relative;
  top: -1px;
  width: 474px;
} */

/* .div-wrapper {
  background-image: url('../images/mask-group-8.png');
  background-size: 100% 100%;
  height: 458px;
  left: -79px;
  position: relative;
  top: -1px;
  width: 474px;
} */

/* .card-wrapper {
  background-image: url('../images/mask-group-10.png');
  background-size: 100% 100%;
  height: 458px;
  left: -79px;
  position: relative;
  top: -1px;
  width: 474px;
} */

/* .overlap {
  background-image: url('../images/mask-group.png');
  background-size: 100% 100%;
  height: 458px;
  left: -79px;
  position: relative;
  top: -1px;
  width: 474px;
} */

