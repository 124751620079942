.partner {
    background-image: url('../../assets/img/level3/img8.png');
}

.background-judul-platinum { 
    transform: skew(-29deg);
    -webkit-transform: skew(-29deg);
    -moz-transform: skew(-29deg);
}

.card-platinum-parnter {
    background-color: #fff;
    padding: 8px;
}

.card-platinum-parnter-judul {
    background-color: #fff;
    padding: 8px;
    border-radius: 15px 15px 0px 0px;
}

.card-platinum-parnter-judul h2 {
    font-size: 20px;
}