.location {
    position: relative;
}
.location .ticket {
    position: absolute;
    width: 70%;
    text-align: center;
    top: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.location-time h2 {
    color: #fff;
    font-weight: 700;
    text-align: center;
}

.card-location {
    height: 500px;
    max-width: 290px;
    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;
    margin: 0 auto;
    border: 2px solid #e8e8e8;
    padding-left: 2px;
}
.card-location img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 290px;
    text-align: center;
}
.card-location img.online {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 290px;
    height: 240.45px;
    text-align: center;
}
.desc-location {
    height: 130px;
    text-align: center;
    color: #214d7c;
    padding: 10px 20px 0;
}
.desc-location p{
    margin-top: 0;
}
.desc-location p:nth-child(1) {
    font-weight: 700;
}
.timer-countdown-card {
    background-color: #5be185;
    border-radius: 10%;
    padding-right: 29px;
    padding-left: 9px;
    text-align: center;
    color: #fff
}
.timer-countdown-card p {
    font-size: 6px;
}

@media (width < 769px) {
    .location {
        padding: 100px 0 40px;
    }
    .location-time h2 {
        font-size: 1.6em;
    }
    .card-location {
        margin-top: 20px;
    }
}

@media (width > 767px) {
    .location {
        padding: 220px 0 50px;
    }
    .card-location {
        margin-top: 50px;
        max-width: 290px;
    }
    .location-time h2 {
        font-size: 2.2em;
    }
}

@media (width > 1023px) {
    .location {
        padding: 300px 0 100px;
    }
}

@media (width > 1439px) {
    .location {
        padding: 390px 0 100px;
    }
}